// Check name icons in https://pictogrammers.com/library/mdi/
import { mdiArrowLeft, mdiArrowRight, mdiClose, mdiAccessPoint, mdiAccount,
  mdiFacebook, mdiHome, mdiInstagram, mdiLinkedin, mdiBookmark, mdiBookmarkOutline, mdiComment,
  mdiClipboardOutline, mdiAlertCircle, mdiCheckCircle, mdiCloseCircle, mdiPlusCircle, mdiAccountOff,
  mdiFilePlus, mdiVideoPlus } from '@mdi/js'

const pharusIcons = {
  mdiArrowLeft, mdiArrowRight, mdiClose, mdiAccessPoint,
  mdiAccount, mdiFacebook, mdiHome, mdiInstagram, mdiLinkedin,
  mdiBookmark, mdiBookmarkOutline, mdiComment, mdiClipboardOutline,
  mdiAlertCircle, mdiCheckCircle, mdiCloseCircle, mdiPlusCircle, mdiAccountOff,
  mdiFilePlus, mdiVideoPlus
}

export default pharusIcons